.invoice{
    padding: 2rem;
}
.invoice .head h2{
 letter-spacing: 5px;
 font-weight: 700;
 color: #ff8acc;
}
.invoice .billto h4{
    font-weight: 500;
    text-transform: capitalize;
color: #ff8acc;
margin-bottom: 0px;
}
.invoice .billto h6{
margin-bottom: 3px;
letter-spacing: 3px;
}
.invoice .billto p svg{
    font-size: 1.2rem;
    color: #ff8acc;
}
.invoice .billto p{
    font-weight: 400 !important;
}
.invoice .total tr th{
background-color: #FF597B !important;
color: #fff;
font-weight: 400;
}
.invoice .amount h5{
    font-weight: 700;
    margin-bottom: 0px;
}
.invoice .amount h6{
    font-weight: 500;
}
/* .invoice .information a svg{
    color: #ff8acc;
    margin-right: 5px;
}
.invoice .information a {
    color: #333;
    margin-left: 60%;
}
.invoice hr{
    border: none;
    height: 2px;
    color: #ff8acc; 
    background-color: #ff8acc; 
}
.invoice .detail h6{
    font-weight: 600;
}
.invoice .detail p{
    font-weight: 400;
    color: #444;
    font-size: 0.9rem;
}
.invoice .billto h5{
    font-weight: 600; 
}
.invoice .billto p.phone,.invoice .shipto p.phone{
    font-weight: 500;
}
.invoice .billto p,.invoice .billto p span{
    font-weight: 400;
    color: #444;
    margin-bottom: 5px;
    font-size: 0.9rem;
}

.invoice .shipto h5{
    font-weight: 600; 
}
.invoice .shipto p,.invoice .shipto p span{
    font-weight: 400;
    color: #444;
    margin-bottom: 5px;
    font-size: 0.9rem;
} */

