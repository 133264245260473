.myevents .app_card img {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  object-fit: contain;
}

.myevents .app_card h5 svg {
  font-size: 1.1rem;
}
.myevents .app_card {
  border: none;
  background-color: #fff;
  height: 100%;
}
.myevents .app_card .date  h5 {
  color: #000;
  font-weight: 400;
  text-align: left;
  font-size: 0.9rem;
}
.myevents .app_card .date  h5 svg{
  color: #ff5b5b;
}
.myevents .app_card p {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 400;
  font-size: 0.9rem;
}
.myevents .app_card p svg {

  font-size: 1.1rem;
  color: #ff5b5b;
}

.myevents .app_card  h5.price {
  color: red;
  font-size: 1.3rem;
  font-weight: 900;
  margin-top: 10px;
}
.myevents .app_card  h5.price svg{
  font-size: 1.3rem !important;
}

@media (min-width: 320px) and (max-width: 426px) {
  .view-head {
    padding: 0px;
  }
}

@media (min-width: 427px) and (max-width: 768px) {
}
