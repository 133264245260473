.page-item a {
  background-color: transparent;
  border: none;
  color: #777;
  font-size: 0.9rem;
}
.page-item.disabled {
  background-color: transparent;
}
.active > .page-link {
  background-color: #7777;
}

.pagination {
  justify-content: end;
}
