
.committee img{
  border: 2px solid #000;
border-radius: 20px;
object-fit: cover;
width: 100px;
height: 100px;
}
.committee h5{
  font-size: 1rem;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 0;
}
.committee p{
  font-size: 0.7rem;
}
.committee tr td.head{
  font-size: 0.9rem;
  font-weight: 800;

}
.committee tr td.body{
  font-size: 0.9rem;
  font-weight: 400
}
@media (max-width: 426px){
  .committee tr td.body{
    font-size: 0.7rem;
  }
  .committee img{
    height: 100px;
    width: 100px;
  }
  .committee .content{
    padding: 10px;
  }
}
@media (min-width: 427px) and (max-width: 768px){

 .committee img{
    height: 100px;
object-fit: contain;
margin-top: 20px;
width: 100px;
  }
  .committee .content{
    padding: 10px;
  }
  .committee tr td.body{
    font-size: 0.8rem;

  }
}