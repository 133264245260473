.profileImgTable {
  position: relative;
  margin: 0 auto;
}
.profileImgTable img {
  width: 100%;
  max-height: 80px;
  object-fit: contain;
}
.profileImgTable a {
  position: absolute;
  top: 0;
  left: 0;
  color: transparent;
  font-size: 0rem;
  display: block;
  width: 100%;
  height: 100%;
}
