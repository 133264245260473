.mytable{
    background-color: #fff;
    padding: 1rem;
        overflow:scroll;
        height:335px;
        
}
.mytable th{
    color: #6c757d;
    font-size: 14.4px;
    font-weight: 700;
    padding: 13.6px;
}
.mytable td{
    padding: 0.85rem;
    color: #6c757d;
    font-size: 14.4px;
    font-weight: 400;
    width: 10px;
}
.mytable h6{
    font-weight: 500;
    color: #343a40;
    font-size: 1rem;
}
.mytable img.adimg{
    border-radius: 50%;
    height: 50px;
    width: 50px;
}


@media (min-width: 320px) and (max-width: 426px) {
    .mytable{
        margin-top: 10px;
        padding: 5px;
    }
    .bQdYbR{
        min-width: 200px !important;
     }
  }
  @media (min-width: 427px) and (max-width: 768px) {
    .mytable{
        margin-top: 10px;
        padding: 5px;
    }
    
    .bQdYbR{
        min-width: 200px !important;
     }

}

