.viewmember .imgbx img {
  position: relative;
}
.viewmember .imgbx a {
  position: absolute;
  top: 40;
  left: 0;
  color: transparent;
  font-size: 1.5rem;
}

/* .viewmember .card{
  position: relative;
  height: 190px;
  background-color: #fff;
  border-radius: 10px;
  border: 0;
  transform: 0.5s;
  margin-top: 15px;
 }
 .viewmember .card:hover{
  height: 450px;
 }
 .viewmember .card .imgbx{
  position: absolute;
  width: 150px;
  height: 150px;
  background-color: #fff;
  left: 50%;
  top: -50px;
  transform: translate(-50%);
  border-radius: 10px;
  box-shadow: 0 15px 50px rgba(0,0,0,0.35);
  overflow: hidden;
  transition: 0.5s;
 }
 .card:hover .imgbx{
  width: 200px;
  height: 200px;
 }
 .viewmember .card .imgbx img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
 }
 .viewmember .card .content{
position: absolute;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: flex-end;
overflow: hidden;
 }
 .viewmember .card .content .details{
  padding: 10px;
  width: 100%;
  transition: 0.5s;
 transform: translateY(200px);

 }
  .card:hover .content .details{
  transform: translateY(0px);

 }
 .viewmember .card .content .details h2{
  font-size: 1.5rem;
  font-weight: 600;
  color: #555;
line-height:1.2;
text-align: center;

 }
 .viewmember .card .content .details h2 span{
  font-size: 1rem;
  font-weight: 500;
  opacity: 0.5;
 }

 .viewmember .card .content .details .data table  {
font-size: 0.8rem;
font-weight: 400;
opacity: 0.5s;
margin-top: 10px;
} */
