.join_member .filebutton {
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 50px);
  flex-direction: column;
}

.join_member label span input {
  z-index: 999;
  line-height: 0;
  font-size: 50px;
  position: absolute;
  top: -2px;
  left: -700px;
  opacity: 0;
  filter: alpha(opacity = 0);
  -ms-filter: "alpha(opacity=0)";
  cursor: pointer;
  _cursor: hand;
  margin: 0;
  padding: 0;
}
.join_member label svg {
  font-size: 7rem;
  color: #eee;
}

.join_member p {
  font-size: 1.1rem;
  margin-top: 12px;
  color: #777;
  font-weight: 300;
  word-spacing: 4px;
  margin-bottom: 0;
  margin-left: 40px;
}
.join_member form {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
@media (min-width: 320px) and (max-width: 426px) {
  .join_member p {
    font-size: 0.6rem;
    margin-left: 0;
  }
  .join_member label svg {
    margin-left: 20px;
  }
  .join_member .down{
    margin-top: 20px;
  }
}
