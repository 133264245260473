.breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 10px;
}

.breadcrumbs a {
  font-size: 0.9rem;
}
