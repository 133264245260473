.error {
  display: flex;
  justify-content: center;
  :global {
    img {
      width: 100%;
      margin: 40px 0;
    }
    h1 {
      font-size: 3rem;
      font-weight: 600;
      text-align: center;
      font-family: Georgia, "Times New Roman", Times, serif;
    }
    h1 span {
      font-size: 4rem;
      color: red;
    }
    p {
      text-align: center;
      font-size: 1.2rem;
      color: #777;
    }
  }
}
