.mill thead .heading{
 background-color: #EAF6F6;
  text-transform: capitalize;
  font-weight: 800;
  color: #000 !important;
}
/* .mill thead{
  background-color: #eee;
} */
.mill thead th{
  color: rebeccapurple;
  font-weight: 800;
}