.gallary{
    margin-bottom: 100px;
    padding: 3rem;
}
.gallary h2{
font-size: 4rem;
    color: #59CE8F;
   font-weight: 800;
   padding-top: 30px;
   text-align: center;
}
.gallary  img{
    height: 300px;
    width: 100%;
    border-radius: 5px;
   margin-top: 50px;
   border: 4px solid #59CE8F;
}
.gallary .content{
    position: relative;
}


/* sm */
.gallary.sm{
   
    padding: 1rem;
}