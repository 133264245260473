.viewevent .app_card img {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  object-fit: contain;
  margin-top: 10px !important;
}
.viewevent .app_card h5 {
  color: #000;
  font-weight: 700;
  padding: 3px;
}
.viewevent .app_card h5 svg {
  font-size: 1.1rem;
}
.viewevent .app_card {
  border: none;
  background-color: #fff;
  height: 100%;
}
.viewevent .app_card .date .time h5 {
  color: #000;
  font-weight: 400;
  text-align: left;
  font-size: 0.9rem;
}
.viewevent .app_card .date .time h5 svg {
  color: #ff5b5b;
  font-size: 1.1rem;
}
.viewevent .app_card p {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 400;
  font-size: 0.9rem;
}
.viewevent .app_card p svg {
  margin-left: 3px;
  font-size: 1.1rem;
  color: #ff5b5b;
}

.viewevent .app_card .price h5 {
  color: red;
  font-size: 1.3rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
}
.viewevent .col-12.col-md-6.col-lg-3.view-head {
  margin-top: 25px;
}
.viewevent .col-12.col-md-6.col-lg-3.view-head:nth-of-type(1),
.viewevent .col-12.col-md-6.col-lg-3.view-head:nth-of-type(2),
.viewevent .col-12.col-md-6.col-lg-3.view-head:nth-of-type(3),
.viewevent .col-12.col-md-6.col-lg-3.view-head:nth-of-type(4) {
  margin: 0px;
}

@media (max-width: 426px) {
  #main > div > div.viewevent > div.row > div:nth-child(1) {
    margin-top: 0px;
  }
  #main > div > div.viewevent > div.row > div {
    margin-top: 20px;
  }
}
@media (min-width: 320px) and (max-width: 426px) {
  .view-head {
    padding: 0px;
  }
}

@media (min-width: 427px) and (max-width: 768px) {
  #main > div > div.viewevent > div.row > div:nth-child(1),
  #main > div > div.viewevent > div.row > div:nth-child(2) {
    margin-top: 0px;
  }
  #main > div > div.viewevent > div.row > div {
    margin-top: 20px;
  }
}
