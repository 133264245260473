.sub .sub_plan {
  padding: 20px;
  background-color: #fff;
  min-height: 310px;
  position: relative;
}
.sub .sub_plan > * {
  color: var(--bs-secondary);
}
.sub .sub_plan .fw-bold.text_danger {
  margin-top: 60px;
}
.sub .sub_plan .ribbon-pop {
  background: linear-gradient(270deg, #4f396a 5.25%, #576bb0 96.68%);
  padding: 10px 40px 10px 20px;
  color: white;
  position: absolute;
  top: 15px;
  left: -15px;
  width: 80%;
  height: 50px;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}
.sub .sub_plan .ribbon-pop:after {
  content: "";
  width: 0;
  height: 0;
  top: 0;
  position: absolute;
  right: 0;
  border-right: 20px solid #fff;
  border-top: 23px solid transparent;
  border-bottom: 30px solid transparent;
}
.sub .sub_plan .ribbon-pop:before {
  height: 0;
  width: 0;
  border-top: 15px solid #381f57;
  border-left: 15px solid transparent;
  bottom: -15px;
  position: absolute;
  content: "";
  left: 0;
}
