.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 240px;
  z-index: 111;
  transition: all 0.3s;
  padding: 20px 0;
  overflow-y: hidden;
  background: #fff;
}
.sidebar .name {
  font-size: 0.9rem;
  font-weight: 600;
}
.sidebar .profile_img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  object-fit: cover;
}

@media (max-width: 1199px) {
  .sidebar {
    top: 65px !important;
    left: -240px;
  }
}

@media (min-width: 1200px) {
  #main,
  #footer {
    margin-left: 240px;
  }
}

@media (max-width: 1199px) {
  .toggle-sidebar .sidebar {
    left: 0;
  }
}

@media (min-width: 1200px) {
  .toggle-sidebar #main,
  .toggle-sidebar #footer {
    margin-left: 0;
  }

  .toggle-sidebar .sidebar {
    left: -240px;
  }
}
.sidebar .logo img {
  height: 100%;
  width: 80px;
}
.sidebar-nav {
  padding: 0;
  margin-left: 3px;
  margin-top: 20px;
  list-style: none;
  height: calc(100vh - 310px);
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
}

.sidebar-nav::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}

.sidebar-nav::-webkit-scrollbar-thumb {
  background-color: #999;
}
.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-item {
  margin: 5px;
}

.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: #6e768e;
  font-weight: 600;
  margin: 20px 0 5px 15px;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 0.83rem;
  color: #6e768e;
  transition: 0.3;
  padding: 12px 15px;
  border-radius: 4px;
  width: 100%;
}

.sidebar-nav .nav-link.active,
.sidebar-nav .nav-link.active svg {
  color: #4154f1 !important;
  font-weight: 500;
}

.sidebar-nav .nav-link.collapsed,
.sidebar-nav .nav-link.collapsed svg {
  color: #6e768e !important;
}

.sidebar-nav .nav-link:hover {
  background: #eee;
}

.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  padding: 5px 0 0 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 600;
  transition: 0.3;
  padding: 10px 0 10px 40px;
  transition: 0.3s;
  color: #6e768e !important;
  position: relative;
}
.sidebar-nav .nav-content a::before {
  content: "";
  background-color: #999;
  width: 1px;
  height: 100%;
  display: block;
  position: absolute;
  left: 20px;
}
.sidebar-nav .nav-content a svg {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}
.sidebar-nav .nav-content a:hover {
  background-color: #eee;
}
.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
  color: #4154f1;
}

.sidebar-nav .nav-content a.active svg {
  background-color: #fff !important;
  display: none !important;
}
.sidebar-nav .nav-content a.active span {
  font-weight: 400;
}
.sidebar-nav .nav-content a.active span:hover {
  background-color: transparent;
}
.sidebar-nav .nav-content :hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}
