.guestdash .boxrow2 .ads h1 {
  background-color: #fff;
}

.guestdash .chart canvas {
  height: 300px !important;
}
.guestdash .chart .polar canvas {
  height: 300px !important;
  margin-left: 25%;
}
@media (min-width: 320px) and (max-width: 426px) {
  .guestdash .chart canvas {
    display: inline;
    width: 100% !important;
    height: 100% !important;
  }
  .guestdash .chart .polar canvas {
    margin-left: 0px;
  }
}
@media (min-width: 427px) and (max-width: 768px) {
  .guestdash .chart canvas {
    display: inline;
    width: 100% !important;
    height: 100% !important;
  }

  .guestdash .chart .polar canvas {
    margin-left: 30% !important;
    width: 40% !important;
  }
}
