.viewmill .headbody td {
  font-weight: 600;
  font-size: 0.9rem;
}
.viewmill .heading {
  color: rebeccapurple;
  font-size: 1.6rem;
  text-transform: uppercase;
}
.viewmill td {
  color: #666;
  font-weight: 500;
  font-size: 0.9rem;
  vertical-align: middle;
}
.viewmill th {
  color: #333;
}

/* sm */
.viewmill.sm th {
  font-size: 0.7rem;
}
.viewmill.sm .headbody td,
.viewmill.sm td {
  font-size: 0.6rem;
  font-weight: 400;
}
.viewmill.sm td a {
  font-size: 0.6rem;
  padding: 2px 8px;
}
.viewmill.sm td a svg {
  display: none;
}
.viewmill.sm .heading {
  font-size: 1rem;
}
