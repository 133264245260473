.classi{
    padding: 3rem;
}
.classi h3 {
    font-size: 3rem;
    font-weight: 700;
    color: #59ce8f;
  }

  .classi h3 span {
    color: #000;
  }
.classi .content .text p{
    text-align: justify;
    font-weight: 500;
    color: #777;
    font-size: 1.1rem;
}
.classi .content .text p span{
   color: red;
   font-weight: 700;
}
.classi .content .come h4{
    position: absolute;
    top: 70%;
    left: 50%;
    transform:translate(-50%, -50%);
    color: #fff;
    font-weight: 700;

}
.classi .content .come p{
    position: absolute;
    top: 80%;
    left: 50%;
    transform:translate(-50%, -50%);
    color: #fff;
    font-weight: 700;

}

/* sm */
.classi.sm{
    padding: 5px;
}
.classi.sm .come img{
    margin-top: 20px;
}

/* md */
.classi.md{
    padding: 10px;
}
.classi.md .come img{
    margin-top: 20px;
    height: 300px;
}
