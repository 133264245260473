.invoice{
    padding: 2rem;
}
.invoice .information a svg{
    color: #ff8acc;
    margin-right: 5px;
}
.invoice .information a {
    color: #333;
    margin-left: 60%;
}
.invoice hr{
    border: none;
    height: 2px;
    color: #ff8acc; 
    background-color: #ff8acc; 
}
.invoice .detail h6{
    font-weight: 600;
}
.invoice .detail p{
    font-weight: 400;
    color: #444;
    font-size: 0.9rem;
}
.invoice .billto h5{
    font-weight: 600; 
}
.invoice .billto p.phone,.invoice .shipto p.phone{
    font-weight: 500;
}
.invoice .billto p,.invoice .billto p span{
    font-weight: 400;
    color: #444;
    margin-bottom: 5px;
    font-size: 0.9rem;
}
/* shipto */
.invoice .shipto h5{
    font-weight: 600; 
}
.invoice .shipto p,.invoice .shipto p span{
    font-weight: 400;
    color: #444;
    margin-bottom: 5px;
    font-size: 0.9rem;
}



























/* .invoice h3{
    font-weight: 700;
}

.invoice .add h4{
    font-weight: 600;
    color: rebeccapurple;
}
.invoice .add p{
    font-weight: 400;
    margin-top: 10px;
}
.invoice .add h6{
    font-weight: 700;
    color: red;
}
.invoice .add h6 span{
    font-weight: 500;
    margin-left: 10px;
    color: #333;
}

.invoice .amt h4{
    background-color: rebeccapurple;
    padding: 7px;
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
}
.invoice .amt h4 span{
    margin-left: 40%;
}
.invoice .amt h6{
    font-weight: 700;
    margin-top: 10px;
}
.invoice .amt h6 span{
    font-weight: 500;
}

.invoice .billto h5{
    color: red;
    font-weight: 500;
}
.invoice .billto h4{
    margin-top: 10px;
    font-weight: 600;
    color: rebeccapurple;
}
.invoice .billto p{
    font-weight: 400;
    margin-top: 10px;
}
.invoice .billto h6{
    font-weight: 700;
    color: red;
}
.invoice .billto h6 span{
    font-weight: 500;
    margin-left: 10px;
    color: #333;
}


.invoice .shipto h5{
    color: red;
    font-weight: 500;
}
.invoice .shipto h4{
    margin-top: 10px;
    font-weight: 600;
    color: rebeccapurple;
}
.invoice .shipto p{
    font-weight: 400;
    margin-top: 10px;
}

.invoice .total h6{
    font-weight: 700;
    color: #000;

}
.invoice .total h6 span{
    font-weight: 500;
    margin-left: 10px;
    color: #333;
}

@media (min-width : 320px) and (max-width : 426px){
    .invoice .amt h4{
       font-size: 1rem;
       margin-top: 20px;
    }
    .invoice .amt h4 span{
        margin-left: 0;
    }
    .invoice .total h6{
    font-weight: 500;
    font-size: 0.9rem;
    }
}

@media (min-width : 427px) and (max-width : 768px){
    .invoice .amt h4{
        font-size: 1rem;
        margin-top: 20px;
     }
 
} */