.mynav {
  position: sticky;
  top: 0;
  z-index: 111;
  padding: 0 10px !important;
}
.mynav .navbar-brand img {
  width: 100px;
}
.mynav .nav_warpper {
  background-color: #fff;
  padding: 1rem 1.5rem;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.mynav .navbar-nav {
  flex: 75%;
  justify-content: center;
  gap: 20px;
}
.mynav .navbar-nav a {
  font-weight: 700;
  color: #333;
}
.auth_btn {
  flex: 25%;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.mynav a.login {
  text-decoration: none;
  color: #fff;
  padding: 7px 20px;
  border-radius: 5px;
  font-weight: 500;
  background-color: #e14d2a;
  padding: 8px 30px;
  border: 0;
}
.mynav a.login:hover {
  color: #fff !important;
  box-shadow: 0 0 0;
}
.mynav .reg {
  color: #e14d2a;
  font-weight: 500;
}
.mynav .navbar-toggler {
  padding: 0;
  border: 0;
}
.mynav .navbar-toggler:focus {
  box-shadow: 0 0 0;
}
.mynav.sm .navbar-brand {
  flex: 1;
}
.mynav.sm .navbar-nav,
.mynav.md .navbar-nav {
  gap: 10px;
  margin-top: 15px;
}
.mynav.md .d-flex.ms-auto,
.mynav.sm .d-flex.ms-auto {
  flex-direction: column;
  align-items: flex-start !important;
}
.mynav.md .reg,
.mynav.sm .reg {
  margin: 10px 0 20px 0 !important;
}
.mynav.sm .auth_btn .reg{
  flex: 50%;
}
.mynav.md .auth_btn .reg{
  flex: 50%;
}

@media (min-width: 992px) and (max-width: 1439px) {
  .mynav {
    padding: 5px 10px;
  }
  .mynav .navbar-nav a {
    font-size: 0.8rem;
  }
  .mynav .reg {
    font-size: 0.8rem;
  }
}
