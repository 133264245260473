.viewads .viewcard .va_btn svg {
  font-size: 1.7rem;
  margin-left: 20px;
}
.viewads .viewcard {
  border: 0;
  background-color: #fff;
  height: 100%;
}

.viewads .viewcard img {
  width: 100%;
  border-radius: 20px;
  padding: 10px;
  height: 12em;
  object-fit: contain;
}
.viewads .viewcard .details {
  padding: 10px;
}
.viewads .viewcard .details .position h5 {
  font-weight: 600;
  font-size: 1.2rem;
}

.viewads .viewcard .details .duration h5 {
  font-weight: 400;
  font-size: 0.9rem;
}
.viewads .viewcard .details .duration h5 svg,
.viewads .viewcard .details .date h5 svg,
.viewads .viewcard .details .position h5 svg,
.viewads .viewcard .details .cost h5 svg {
  color: red;
}
.viewads .viewcard .details .cost h5 {
  font-weight: 400;
  font-size: 0.9rem;
}
.viewads .viewcard .details .date h5 {
  font-weight: 400;
  font-size: 0.9rem;
}
.viewads .col-sm-12.col-md-6.col-lg-3 {
  margin-top: 25px;
}
.viewads .col-sm-12.col-md-6.col-lg-3:nth-of-type(1),
.viewads .col-sm-12.col-md-6.col-lg-3:nth-of-type(2),
.viewads .col-sm-12.col-md-6.col-lg-3:nth-of-type(3),
.viewads .col-sm-12.col-md-6.col-lg-3:nth-of-type(4) {
  margin: 0px;
}

@media (max-width: 426px) {
  #main > div > div.viewads > div.row > div:nth-child(1) {
    margin-top: 0px;
  }
  #main > div > div.viewads > div.row > div {
    margin-top: 20px;
  }
  .viewads .viewcard .details {
    padding: 10px;
  }
  .viewads .viewcard .button {
    margin-left: 0;
    margin-top: 5px;
  }

  .viewads .viewcard .button .approved button {
    padding: 8px 46px 8px 46px;
    margin-top: 5px;
  }
  .viewads .viewcard .button .payment button {
    padding: 8px 26px 8px 26px;
    font-weight: 600;
  }
  .viewads .viewcard .button .not button {
    padding: 5px 10px;
    font-weight: 600;
    margin-top: 5px;
  }

  .viewads .viewcard .button .cancel {
    margin: 0px;
    margin-left: 60px !important;
  }
}

@media (min-width: 427px) and (max-width: 960px) {
  #main > div > div.viewads > div.row > div:nth-child(1),
  #main > div > div.viewads > div.row > div:nth-child(2) {
    margin-top: 0px;
  }
  #main > div > div.viewads > div.row > div {
    margin-top: 20px;
  }
}
