.loader {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 11111;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.spin {
  animation: spin 0.5s infinite linear;
  font-size: 2rem;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

@media (min-width: 1220px) {
  .loader.dash_loader {
    width: calc(100% - 240px);
    height: calc(100% - 65px);
    right: 0;
    left: auto;
    bottom: 0;
    top: auto;
    background-color: #ebeff2;
    z-index: 11 !important;
  }
  .toggle-sidebar .loader.dash_loader {
    width: 100%;
  }
}
