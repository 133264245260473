.page_header {
  position: relative;
  height: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url("../../../assets/img/manufacture.webp");
  background-size: cover;
  background-position: 100%;
}
.page_header::after {
  content: "";
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.page_header .content {
  text-align: center;
  z-index: 1;
}
.page_header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page_header h1 {
  font-size: 5rem;
  color: #fff;
  font-weight: 800;
  margin-bottom: 20px;
}

/* sm */
.page_header.sm h1 {
  font-size: 3rem;
}
