.ViewMaterials .pdf_box {
  max-height: 27em ;
  padding-bottom: 10px;
  height: 400px;
}
.ViewMaterials .pdf_box .content p.pdf_head {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 8px;
  color: #555;
}
.ViewMaterials .pdf_box .content p {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: justify;
  font-size: 0.6rem;
  color: #888;
  font-weight: 500;
  padding: 0px 10px;
}
.ViewMaterials .content h6 {
  text-align: center;
  font-weight: 700;
  font-size: 1.1rem;
  margin-top: 10px;
}
.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.ViewMaterials .pdf_box img {
  height: 220px;
  padding: 5px;
  width: 100%;
  border-radius: 10px !important;
  object-fit: contain;
}
.ViewMaterials  .col-12.col-md-4.col-lg-3 {
  margin-top: 25px;
}
.ViewMaterials .col-12.col-md-4.col-lg-3:nth-of-type(1),
.ViewMaterials .col-12.col-md-4.col-lg-3:nth-of-type(2),
.ViewMaterials .col-12.col-md-4.col-lg-3:nth-of-type(3),
.ViewMaterials .col-12.col-md-4.col-lg-3:nth-of-type(4) {
  margin: 0px;
}
@media (min-width: 320px) and (max-width: 426px) {
  .pdf_box {
    margin-top: 20px;
  }
}
@media (min-width: 427px) and (max-width: 768px) {
  .ViewMaterials .pdf_box .content p {
    padding: 0px 10px;
  }
}
