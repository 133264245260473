.app_btn {
  color: #fff !important;
  border: 1px solid transparent;
  outline: none;
  font-weight: 500;
  font-size: 0.9rem;
  padding: 10px 20px;
  text-align: center;
  border-radius: 2px !important;
}
.app_btn.sm {
  font-size: 0.8rem;
  padding: 5px 3px;
}
.primary {
  background-color: #71b6f9;
}
.primary:hover {
  background-color: #609bd4 !important;
}
.success {
  background-color: #10c469;
}
.success:hover {
  background-color: #0ea759 !important;
}
.info {
  background-color: #35b8e0;
}
.danger {
  background-color: #ff5b5b;
}
.danger:hover {
  background-color: #d94d4d;
}
.warning {
  background-color: #f9c851;
}
.outline {
  border: 1px solid #ff5b5b;
  background-color: transparent !important;
}
.outline.danger {
  color: #ff5b5b !important;
}

/* Soft btns */

.soft_primary {
  background-color: #e5f2fe;
  color: var(--primary) !important;
}

.btn:hover {
  border: 0;
  box-shadow: 0 0 0;
}
