.footer {
  background-image: url("assets/img/sidebar_bg.jpg");
  margin-top: 30px;
}
.footer .abt h3 {
  color: #fff;
  padding-top: 50px;
  font-weight: 700;
}
.footer .abt p {
  color: #fff;
  text-align: justify;
}
.footer .con h3 {
  color: #fff;
  padding-top: 50px;
  font-weight: 700;
  margin-left: 30px;
}
.footer .con a {
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
}
.footer .con a:hover {
  background-color: transparent;
  color: #fff !important;
  font-weight: 400;
}
.footer .con a svg {
  font-size: 1.5rem;
}
.footer .links h3 {
  color: #fff;
  padding-top: 50px;
  font-weight: 700;
}
.footer .links a {
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
}
.footer .links a:hover {
  background-color: transparent;
  color: #fff !important;
  font-weight: 400;
}
.footer hr {
  color: #fff;
}
.footer .right p {
  color: #fff;
}
/* sm */
.footer.sm {
  height: 100%;
}
.footer.sm .con a {
  text-decoration: none;
  color: #fff;
  font-size: 0.93rem;
  margin-left: 0;
}
.footer.sm .con h3,
.footer.md .con h3 {
  margin-left: 0;
}

.footer.sm .con a svg {
  font-size: 1.3rem;
  margin-left: 0;
}
.footer.sm .right p {
  color: #fff;
  font-size: 0.8rem;
  margin-bottom: 0 !important;
}

/* md */
.footer.md .con a {
  margin: 0;
}
