.classified .picture{
    position: relative;
}
.classified .picture img{
    height: 300px;
}
.classified .picture .inside h5{
    font-size: 2rem;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 11111;
    color: #fff;
}
.classified .picture .inside h6{
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 11111;
    color: #fff;
}

.classified .content p{
    text-align: justify;
    font-weight: 500;
  line-height: 2;
}
.classified .content p span{
color: red;
font-weight: 700;
}
.classified .content h3{
    text-align: center;
    color: rebeccapurple;
    font-weight: 700;
}
.classified .content{
background-color: ghostwhite;   
    height: 100%;
}

/* benefits */
.classified .benefits p{
    text-align: justify;
    font-weight: 500;
  line-height: 2;
}
.classified .benefits p span{
color: red;
font-weight: 700;
}
.classified .benefits h3{
    text-align: center;
    color: rebeccapurple;
    font-weight: 700;
}
.classified .benefits{
    background-color: ghostwhite;   
    height: 100%;
}

/* offers */

.classified .offers p{
    text-align: justify;
    font-weight: 500;
  line-height: 2;
}
.classified .offers p span{
color: red;
font-weight: 700;
}
.classified .offers h3{
    text-align: center;
    color: rebeccapurple;
    font-weight: 700;
}
.classified .offers{
    background-color: ghostwhite;   
    height: 100%;
}

/* rules */

.classified .rules h3{
    text-align: center;
    color: rebeccapurple;
    font-weight: 700;
}
.classified .rules{
    background-color: ghostwhite;   
    height: 100%;
}
.classified .rules .icon span svg{
    color: red;
    margin-right: 5px;
}
.classified .rules .icon p{
font-weight: 600;
}

/* payment */
.classified .payment h3{
    text-align: center;
    color: rebeccapurple;
    font-weight: 700;
}
.classified .payment{
    background-color: ghostwhite;   
    height: 100%;
}
.classified .payment .icon span svg{
    color: red;
    margin-right: 5px;
}
.classified .payment .icon p{
font-weight: 800;

}

/* img */
.classified .carousel img{
    height: 21em;
}

/* sm */
.classified.sm .picture img{
    margin-top: 20px;
}
.classified.sm  .pic3 img{
    margin-top: 60px;
}
.classified.sm .benefits{
    margin-top: 20px;
}
.classified.sm .benefits h3{
    font-size: 1.2rem;
}
.classified.sm .offers{
    margin-top: 40px;
}
.classified.sm .offers h3{
    font-size: 1.2rem;
}
.classified.sm .rules{
    margin-top: 10px;
}
.classified.sm .rules h3{
    font-size: 1.2rem;
}
.classified.sm .payment{
    margin-top: 30px;
}
.classified.sm .payment h3{
    font-size: 1.2rem;
}


/* md */
.classified.md .picture img{
    margin-top: 30px;
}
.classified.md .benefits{
    margin-top: 20px;
}
.classified.md .offers{
    margin-top: 30px;
}
.classified.md .rules{
    margin-top: 20px;
}
.classified.md .payment{
    margin-top: 30px;
}
.classified.md  .pic3 img{
    margin-top: 50px;
}
