.adminviewads .mycard1 .photo{
  position: relative;
}
.adminviewads .mycard1 .photo a{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 9rem;
  color: transparent;

}
.adminviewads .mycard1 {
  border: 0;
  background-color: #fff;
}
.adminviewads .col-sm-12.col-md-6.col-lg-3 {
  margin-top: 25px;
}
.adminviewads .col-sm-12.col-md-6.col-lg-3:nth-of-type(1),
.adminviewads .col-sm-12.col-md-6.col-lg-3:nth-of-type(2),
.adminviewads .col-sm-12.col-md-6.col-lg-3:nth-of-type(3),
.adminviewads .col-sm-12.col-md-6.col-lg-3:nth-of-type(4) {
  margin: 0px;
}
.adminviewads .mycard1 .photo img {
  width: 100%;
  padding: 10px;
  height: 15em;
  object-fit: contain;
}
.details {
  padding: 10px;
}
.adminviewads .mycard1 .details .position h5 {
  font-weight: 700;
  font-size: 1.5rem;
  color: rebeccapurple;
  margin-top: 5px;
}

.adminviewads .mycard1 .details .duration h5 {
  font-weight: 500;
  font-size: 0.9rem;
}
.adminviewads .mycard1 .details .duration h5 svg,
.adminviewads .mycard1 .details .cost h5 svg {
  color: red;
  font-size: 0.9rem;
}
.adminviewads .mycard1 .details .cost h5 {
  font-weight: 500;
  font-size: 0.9rem;
  color: #000;
}

@media (max-width: 426px) {
  #main > div > div.adminviewads > div.row > div:nth-child(1) {
    margin-top: 0px;
  }
  #main > div > div.adminviewads > div.row > div {
    margin-top: 20px;
  }
  .details {
    padding: 5px;
  }
  .adminviewads .mycard1 .button {
    margin-left: 0;
    margin-top: 5px;
  }

  .adminviewads .mycard1 .button .approved button {
    padding: 8px 46px 8px 46px;
    margin-top: 5px;
  }

  .adminviewads .mycard1 .button .payment button {
    padding: 8px 26px 8px 26px;
    font-weight: 600;
    margin-top: 5px;
  }

  .adminviewads .mycard1 .button .not button {
    padding: 5px 10px;
    font-weight: 600;
    margin-top: 5px;
  }

  .adminviewads .mycard1 .button .cancel {
    margin: 0px;
    padding: 5px 10px;
    margin-left: 25% !important;
  }
}

@media (min-width: 427px) and (max-width: 960px) {
  #main > div > div.adminviewads > div.row > div:nth-child(1),
  #main > div > div.adminviewads > div.row > div:nth-child(2) {
    margin-top: 0px;
  }
  #main > div > div.adminviewads > div.row > div {
    margin-top: 20px;
  }
}
