.header {
  background-color: #ebeff2 !important;
  position: sticky;
  top: 0;
}

@media (min-width: 1200px) {
  .header .logo {
    width: 230px;
  }
}
.header .logo img {
  max-height: 26px;
  margin-right: 6px;
}
.header .logo {
  color: #fff;
  font-size: 1.5rem;
  margin-top: 2px;
  font-weight: 700;
}

.header {
  transition: all 0.5s;
  z-index: 997;
  height: 65px;
  background-color: #fff;
  padding-left: 20px;
}

.header .toggle-sidebar-btn {
  font-size: 32px;
  cursor: pointer;
  color: #999;
}

.header .search-bar {
  width: 100%;
}

@media (max-width: 426px) {
  .header .search_icon {
    display: block !important;
  }
  .header .search-form input {
    width: 100% !important;
  }
  .header .search-form input::placeholder {
    color: rgba(0, 0, 0, 0.6) !important;
  }
  .header .search-form button {
    color: #333 !important;
  }
  .header .search-bar {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    padding: 10px;
    box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
    background: white;
    z-index: 1;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
  }

  .header .search-bar-show {
    top: 65px;
    visibility: visible;
    opacity: 1;
  }
}

.header .search-form {
  width: 100%;
}

.header .search-form input {
  font-size: 14px;
  color: #333;
  border: none;
  padding: 10px 38px 10px 15px;
  border-radius: 25px;
  background-color: #e1e5e9;
  transition: 0.3s;
  width: 100%;
}
.header .search-form input:focus,
.header .search-form input:hover {
  outline: none !important;
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.6);
  transition: 0.3s;
}

.header .search-form button {
  border: 0;
  padding: 0;
  margin-left: -30px;
  background: none;
  color: #fff;
}

/*--------------------------------------------------------------
  # Header Nav
  --------------------------------------------------------------*/
.header-nav ul {
  list-style: none;
}

.header-nav > ul {
  margin: 0;
  padding: 0;
}

.header-nav .nav-icon {
  font-size: 22px;
  color: var(--bs-secondary);
  margin-right: 25px;
  position: relative;
}

.header-nav .nav-profile {
  color: #fff;
}

.header-nav .nav-profile img {
  max-height: 36px;
}

.header-nav .nav-profile span {
  font-size: 14px;
  font-weight: 600;
  color: #6c757d;
}

.header-nav .badge-number {
  position: absolute;
  inset: -2px -5px auto auto;
  font-weight: normal;
  font-size: 12px;
  padding: 3px 6px;
}

.header-nav .notifications {
  inset: 8px -15px auto auto !important;
}

.header-nav .notifications .notification-item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  transition: 0.3s;
}

.header-nav .notifications .notification-item i {
  margin: 0 20px 0 10px;
  font-size: 24px;
}

.header-nav .notifications .notification-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.header-nav .notifications .notification-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.header-nav .notifications .notification-item:hover {
  background-color: #f6f9ff;
}

.header-nav .messages {
  inset: 8px -15px auto auto !important;
}

.header-nav .messages .message-item {
  padding: 15px 10px;
  transition: 0.3s;
}

.header-nav .messages .message-item a {
  display: flex;
}

.header-nav .messages .message-item img {
  margin: 0 20px 0 10px;
  max-height: 40px;
}

.header-nav .messages .message-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #444444;
}

.header-nav .messages .message-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.header-nav .messages .message-item:hover {
  background-color: #f6f9ff;
}

.header-nav .profile {
  top: 8px !important;
}

.header-nav .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: #444444;
}

.header-nav .profile .dropdown-item {
  font-size: 0.8rem;
  padding: 10px;
  transition: 0.3s;
}

.header-nav .profile .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.header-nav .profile .dropdown-item:hover {
  background-color: #eee;
}
