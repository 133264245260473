.mycard{
    padding: 1.5rem;
    background-color: #fff;
    height: 100% !important;
}
.mycard .head h6{
    font-weight: 500;
    color: #343a40;
    font-size: 1rem;

}
.mycard .body{
    margin-top: 30px;
    align-items: center;

}
.mycard .body .icon svg{
    font-size: 3.5rem;
    color: #ff5b5b;

}
.mycard .body p.app_label{
margin-top: 0px;
}
.mycard .body .count h5{
    font-size: 2rem;
    color: #343a40;
    font-weight: 800;
    margin-bottom: 0px !important;
}
.mycard .body .dropdown-menu .dropdown-item{
    background-color: transparent;
}
.dropdown-item.active{
    background-color: white;
    color: #000;
}
/* green */
.mycard .body .icon.green svg{
    color: #10c469;
}
/* yellow */
.mycard .body .icon.yellow svg{
    color: #f5c851;
}
/* pink */
.mycard .body .icon.pink svg{
    color: #ff8acc;
}
.mycard .dropdown {
    
}
@media (min-width: 320px) and (max-width: 426px) {
    .mycard{
        margin-top: 10px;
    }
  }
  @media (min-width: 427px) and (max-width: 768px) {
    .mycard{
        margin-top: 10px;
    }
}
