.poinvoice{
    position: relative;
}
.poinvoice .cmpname{
    position: absolute;
    top: 9%;
  left: 25%;
}
.poinvoice .cmpadd{
    position: absolute;
    top: 15%;
  left: 3%;
}
.poinvoice .ponum{
    position: absolute;
    top: 9.3%;
  left: 70%;
}
.poinvoice .podate{
    position: absolute;
    top: 12.5%;
  left: 66%;
}
.poinvoice .venid{
    position: absolute;
    top: 15.5%;
  left: 74%;
}
.poinvoice .sellername{
  position: absolute;
  top: 26.5%;
left: 22%;
}
.poinvoice .selleradd{
  position: absolute;
  top: 33%;
left: 3%;
}
.poinvoice .buyername{
  position: absolute;
  top:26.5%;
left: 77%;
}
.poinvoice .buyeradd{
  position: absolute;
  top: 33%;
left: 57%;
}
.poinvoice .ship{
  position: absolute;
  top: 52%;
left: 15%;
}
.poinvoice .payterm{
  position: absolute;
  top: 52%;
left: 43%;
}
.poinvoice .datereq{
  position: absolute;
  top: 52%;
left: 73%;
}
.poinvoice .desc{
  position: absolute;
  top: 61%;
left: 5%;
}
.poinvoice .quant{
  position: absolute;
  top: 61%;
left: 45%;
}
.poinvoice .unitprice{
  position: absolute;
  top: 61%;
left: 65%;
}
.poinvoice .amt{
  position: absolute;
  top: 61%;
left: 85%;
}
.poinvoice .total{
  position: absolute;
  top: 92%;
left: 85%;
}