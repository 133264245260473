.onlineId .card_bg {
  width: 400px;
  height: 600px;
}
.onlineId .profile_pic {
  position: absolute;
  top: 90.5px;
  left: 145px;
}
.onlineId .profile_pic img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.onlineId .user_info {
  position: absolute;
  top: 220px;
  left: 30px;
}
.onlineId .user_info td.id_head{
  font-weight: 700;
  color: #333;
}

/* sm */
.onlineId.sm .profile_pic  {
left: 33%;
top: 87px;
}
.onlineId.sm .user_info {
left: 20px;
}
