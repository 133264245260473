.change_profile label.filebutton {
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
}

.change_profile label span input {
  z-index: 999;
  line-height: 0;
  font-size: 50px;
  position: absolute;
  top: -2px;
  left: -700px;
  opacity: 0;
  filter: alpha(opacity = 0);
  -ms-filter: "alpha(opacity=0)";
  cursor: pointer;
  _cursor: hand;
  margin: 0;
  padding: 0;
}
.change_profile label svg {
  font-size: 7rem;
  color: #a3d0fb;
}

.change_profile p {
  color: #000;
  font-size: 1rem;
  margin-top: 12px;
  color: #777;
  font-weight: 300;
  word-spacing: 4px;
  margin-bottom: 0;
  margin-left: 40px;
}
.change_profile form {
  background-color: #e5f2fe;
  width: 100%;
  height: 100%;
  padding: 1rem;
  border-radius: 5px;
}
@media (min-width: 320px) and (max-width: 426px) {
  .change_profile p {
    font-size: 0.6rem;
    margin-left: 0;
  }
  .change_profile label svg {
    margin-left: 20px;
  }
}
