.app_input {
  margin-top: 10px;
  border: 1px solid #9999;
  background-color: #fff;
  padding: 7px 15px;
  width: 100%;
  outline: none;
  font-size: 0.9rem;
}
.app_input:focus {
  border: 1px solid var(--primary) !important;
}

.app_label {
  font-size: 0.9rem;
  color: #6c757d;
  font-weight: 500;
  margin-top: 10px;
}
