.contact {
  padding: 3rem;
}
.contact h3 {
  font-size: 3rem;
  font-weight: 700;
  color: #59ce8f;
}
.contact .form h3 {
  color: rebeccapurple;
  font-size: 2rem;
  font-weight: 800;
}
.contact h3 span {
  color: #000;
}

.contact p.light {
  text-align: center;
  color: #7777;
}
.contact .map iframe {
  width: 100%;
  height: 350px;
}
.contact form input {
  margin-top: 25px;
  border-radius: 10;
  width: 100%;
  background-color: #eee;
  border: none;
}
.contact form textarea {
  border-radius: 10;
  width: 100%;
  margin-top: 20px;
  background-color: #eee;
  border: none;
}
.contact .form a {
  text-decoration: none;
  text-align: center;
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 20px !important;
}
.contact .content {
  color: #fff;
  width: 100%;
}
.contact .content h4 {
  font-weight: 700;
  color: #59ce8f;
  font-size: 2rem;
}
.contact .content p {
  color: #333;
}
.contact .content p svg {
  font-size: 1.8rem;
  color: #59ce8f;
}
.contact .content a:hover {
  text-decoration: none;
  color: #333 !important;
  font-weight: 400;
}
.contact .content a {
  text-decoration: none;
  color: #333;
  font-size: 1.2rem;
}
.contact .content svg {
  font-size: 1.8rem;
  color: #59ce8f;
}

.contact .form iframe {
  height: 430px;
  margin-bottom: 50px;
  margin-top: 20px;
  width: 100%;
}

/* sm */
.contact.sm {
  padding: 5px;
}
.contact.sm h3 {
  font-size: 1.5rem;
  margin: 30px 0;
}
.contact.sm .content,
.contact.md .content {
  margin-top: 20px;
  margin-left: 0 !important;
  padding-bottom: 30px;
}

.contact.sm .content a svg,
.contact.sm .content p svg {
  font-size: 1.5rem;
}
.contact.sm .form iframe {
  margin-top: 0 !important;
}
/* md */

.contact.md .button a {
  margin-left: 30%;
}

.contact.md h3 {
  padding-top: 0;
}
