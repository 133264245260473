.adminviewpdf .vm_btn svg{
    font-size: 1.4rem;
}
.adminviewpdf .imgbx img{
    position: relative;

}
.adminviewpdf .imgbx a{
    position: absolute;
    top: 40;
    left: 0;
color:transparent;
font-size: 1.3rem;
}

.adminviewpdf .viewpdf a{
    text-decoration: none;
}
.adminviewpdf .viewpdf a svg{
    font-size: 1.5rem;
}
@media (min-width: 320px) and (max-width: 426px) {
    .adminviewpdf .vm_btn svg{
        font-size: 1.2rem;
    }
  }