.guestdash .ads img {
  height: 500px;
  width: 564px;
  object-fit: cover;
}

@media (min-width: 320px) and (max-width: 426px) {
  .guestdash .polar {
    margin-top: 15px;
  }
}
