.myprofile img {
  height: 10em;
  border-radius: 50%;
}
.myprofile .view {
  margin-top: 10%;
}
.myprofile .view a {
  font-size: 1.3rem;
  background-color: #f1c40f;
  color: #fff;
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px solid #eee;
  font-weight: 500;
}
.myprofile .view a svg {
  font-size: 1.5rem;
}
.myprofile .personal,
.myprofile .company,
.myprofile .resident {
  border: 1px solid #8888;
  padding: 1rem;
}
.myprofile .personal h5,
.myprofile .company h5,
.myprofile .resident h5 {
  font-size: 1.2rem;
  color: #888;
  font-weight: 600;
}
.myprofile .personal h6,
.myprofile .company h6,
.myprofile .resident h6 {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 2px;
  color: #000;
}
.myprofile .personal p,
.myprofile .company p,
.myprofile .resident p {
  font-weight: 400;
  font-size: 1rem;
  color: #333;
  margin-bottom: 2px;
}

@media (min-width: 320px) and (max-width: 426px) {
  .myprofile .head h3 {
    font-size: 1.5rem;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .myprofile .view {
    margin-top: 5% !important;
  }
  .myprofile .view a {
    font-size: 1rem;
  }
  .myprofile .company {
    margin-top: 1px;
  }
  .myprofile .personal,
.myprofile .company,
.myprofile .resident {
margin-top: 15px;
}
}

@media (min-width: 427px) and (max-width: 768px) {
  .myprofile .head h3 {
    font-size: 2.2rem;
  }
  .myprofile .view {
    font-size: 1rem;
    margin-top: 12% !important;
  }
}

