.about {
  padding: 5rem 0;
  background-color: #fff;
}
.about .image img {
  height: 100%;
}
.about .content {
  padding-left: 20px;
}
.about .content h4 {
  font-weight: 700;
  font-size: 4rem;
  margin-bottom: 0;
}
.about .content h4 span {
  font-size: 4rem;
  color: #59ce8f;
}
.about .content .he p {
  margin-top: 0;
  padding: 0;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 600;
}
.about .content p {
  font-size: 1rem;
  color: #777;
  line-height: 2;
  font-weight: 400;
  margin-top: 10px;
  text-align: justify;
}
.about .content p span {
  color: #f00000;
  font-weight: 700;
}
.about .content .blur {
  filter: blur(0.2px);
  border-radius: 10px;
}
.about .content a{
  background-color: #e14d2a;
  color: #fff;
  padding: 7px 20px;
  font-weight: 600;
  border-radius: 5px;
}

/* sm */
.about.sm {
  margin: 20px 0;
  padding: 50px 5px !important;
}
.about.sm .content {
  padding: 0;
}
.about.sm .content h4,
.about.sm .content h4 span,
.about.md .content h4,
.about.md .content h4 span {
  margin: 30px 0 20px 0;
  font-size: 2.2rem;
}
