.certificate {
  position: relative;
}
.certificate .name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -65%);
}
.certificate .address {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -65%);
}
.certificate .issuedate {
  position: absolute;
  top: 91%;
  left: 45%;
  transform: translate(-50%, -65%);
}
.certificate .validdate {
  position: absolute;
  top: 91%;
  left: 70%;
  transform: translate(-50%, -65%);
}
.certificate .sign1 {
  position: absolute;
  top: 75%;
  left: 25%;
  transform: translate(-50%, -65%);
}
.certificate .sign2 {
  position: absolute;
  top: 75%;
  left: 47%;
  transform: translate(-50%, -65%);
}
.certificate .sign3 {
  position: absolute;
  top: 75%;
  left: 70%;
  transform: translate(-50%, -65%);
}
.certificate .sign1 img,
.certificate .sign2 img,
.certificate .sign3 img {
  height: 70px;
}
@media (max-width: 426px) {
  .certificate .name {
    font-size: 0.6rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .certificate .name h1 {
    font-size: 0.7rem;
  }
  .certificate .address h5 {
    font-size: 0.5rem;
  }
  .certificate .issuedate {
    top: 93%;
  }
  .certificate .issuedate h6 {
    font-size: 0.3rem;
  }
  .certificate .validdate h6 {
    font-size: 0.3rem;
  }
  .certificate .validdate {
    top: 93%;
  }
  .certificate .sign1 img,
  .certificate .sign2 img,
  .certificate .sign3 img {
    height: 25px;
  }
}
@media (min-width: 427px) and (max-width: 768px) {
  .certificate .name h1 {
    font-size: 1rem;
  }
  .certificate .address h5 {
    font-size: 0.9rem;
  }
  .certificate .issuedate h5 {
    font-size: 0.5rem;
  }
  .certificate .issuedate {
    top: 91.5%;
  }
  .certificate .validdate {
    top: 91.5%;
  }
  .certificate .validdate h5 {
    font-size: 0.6rem;
  }
  .certificate .sign1 img,
  .certificate .sign2 img,
  .certificate .sign3 img {
    height: 35px;
  }
}
