.uploadpdf .uploadimg{
    background-color: #fc69;
}
.uploadpdf .uploadimg label.filebutton {
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;
  }
  
  .uploadpdf .uploadimg label span input {
    z-index: 999;
    line-height: 0;
    font-size: 50px;
    position: absolute;
    top: -2px;
    left: -700px;
    opacity: 0;
    filter: alpha(opacity = 0);
    -ms-filter: "alpha(opacity=0)";
    cursor: pointer;
    _cursor: hand;
    margin: 0;
    padding: 0;
  }
 .uploadpdf  .uploadimg label svg {
    font-size: 5rem;
    color: #888;
   border:1px solid #999;
   border-radius:50%;
   padding: 10px;
   margin-bottom: 10px;
  }









@media (max-width: 426px){
    .box2{
        margin-top: 20px;
    }
}