.members .abt_mem h3,
.members .benefits h3,
.members .eligibility h3,
.members .enrollment h3,
.members .fee h3 {
  text-align: center;
  color: rebeccapurple;
  font-weight: 700;
  margin-top: 10px;
}
.members .fee h3 {
  color: rebeccapurple;
  font-weight: 700;
  margin-top: 10px;
  text-align: start;
}
.members .abt_mem,
.members .benefits,
.members .eligibility,
.members .enrollment,
.members .fee {
  padding: 1rem;
  border-radius: 5px;
  height: 100%;
background-image: url("assets/img/bg.webp"); 
}
.members .eligibility{
    height: 100%;
}
.members .enrollment{
    height: 100%;
}
.members .fee{
    height: 100%;
}
.members .abt_mem p,  .members .enrollment p{
    font-weight: 500;
    margin-top: 30px;
    text-align: justify;
}
.members .eligibility p {
    font-weight: 500;

}
.members .benefits .icon svg,.members .eligibility .red svg{
    color: red;
    margin-right: 5px;
}
.members .fee p span{
    color: red;
    font-weight: 700;
}
.members .fee p{
    font-weight: 500;
    margin-top: 20px;
    text-align: justify;
}
.members .fee .button a{
 
    color: #fff;
    font-weight: 700;
    padding: 10px 20px;
    text-align: center;
    border-radius: 5px;
    width: 50%;
    font-size: 1.1rem;
    background: #283c86;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #45a247, #283c86);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #45a247, #283c86); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.members .benefits p{
  font-weight: 500;
}
.members .benefits p svg,
.members .eligibility p svg {
  color: red;
  margin-right: 5px;
}
.members .fee p span {
  color: red;
  font-weight: 700;
}
.members .fee p {
  font-weight: 500;
  margin-top: 20px;
  text-align: justify;
}
.members .fee .button a {
  margin-top: 30px;
  color: #fff;
  font-weight: 700;
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;
  width: 50%;
  font-size: 1.1rem;
  background: #283c86; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #45a247,
    #283c86
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #45a247,
    #283c86
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.members .fee .button a:hover {
  color: #fff !important;
}

.members .image img {
  height: 300px;
  width: 100%;
  margin-left: 30%;
  margin-top: 20%;
}

@media (min-width: 320px) and (max-width: 426px) {
  .members .benefits{
    margin-top: 30px !important;
  }
  .members .eligibility{
    margin-top: 50px !important;
  }
  .members .enrollment{
    margin-top: 70px !important;
  }
  .members .fee{
    margin-top: 90px !important;
  }
  .members .fee .button a {
    margin-left: 0;
    font-size: 1rem;
    width: 100%;
  }
  .members .pic img {
    margin-left: 0;
    margin-top: 100px;
  }
  .members .abt_mem,
.members .benefits,
.members .eligibility,
.members .enrollment,
.members .fee {
margin-top: 15px;
}
}

@media (min-width: 427px) and (max-width: 768px) {
  .members .pic img {
    margin-left: 0;
    margin-top: 100px !important;
  }
  .members .abt_mem {
    height: 250px !important;
  }
  .members .eligibility{
    margin-top: 40px;
  }
  .members .benefits{
  margin-top: 20px;
  }
  .members .enrollment{
    margin-top: 60px;
  }
  .members .fee{
    margin-top: 80px;
  }
}