.table_search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.table_search input {
  width: 280px;
  padding: 10px 15px;
  border: 2px solid transparent;
  margin-bottom: 10px;
  outline: none;
  border-radius: 35px;
}
.table_search input:focus {
  border: 2px solid var(--primary);
  transition: 0.5s;
}
