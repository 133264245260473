.gal_slider {
  padding: 3rem 0;
  background-color: #fff;
}
.gal_slider img {
  width: 100%;
  height: 250px;
  padding: 10px;
}
.gal_slider h2 {
  font-size: 3rem;
  color: #59ce8f;
  font-weight: 700;
  margin-bottom: 40px;
}
.gal_slider h2 span {
  color: #000;
}
.gal_slider a{
  background-color: #e14d2a;
  color: #fff;
  padding: 7px 20px;
  font-weight: 600;
  border-radius: 5px;
}
@media (min-width: 320px) and (max-width: 426px) {
  .gal_slider {
    margin-top: 40px;
  }
  .gal_slider h2 {
    margin-bottom: 20px;
    font-size: 2rem;
  }
  .gal_slider a {
    font-size: 0.9rem;
    padding: 8px 13px;
  }
}
