.home_bg_banner {
  background: #da22ff;
  background: -webkit-linear-gradient(to right, #9733ee, #da22ff);
  background: linear-gradient(to right, #9733ee, #da22ff);
  width: 100%;
  height: 10em;
  position: absolute;
  top: 0;
}
@media screen and (min-width: 1920px) {
  .home_bg_banner {
    max-width: 1440px;
  }
}
.home_slider {
  margin-top: 15px;
  height: 40em;
}
.home_slider img {
  height: 100vh;
  width: 100% !important;
}
.home_slider .carousel,
.home_slider .carousel-inner {
  height: 100%;
}
.home_slider .carousel-inner img {
  object-fit: cover;
}
.home_slider .carousel-control-prev,
.home_slider .carousel-control-next {
  z-index: 11;
  opacity: 1;
}

.home_slider .carousel-control-next-icon,
.home_slider .carousel-control-prev-icon {
  background: url("../../../assets/icon/right-arrow.svg") #fff;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-position: center;
  background-size: 100%;
}
.home_slider .carousel-control-prev-icon {
  background-image: url("../../../assets/icon/left-arrow.svg");
}
.home_slider .slider_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  width: calc(100% - 24px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 8em;
}
.home_slider .slider_content h1 {
  font-size: 8rem;
  background: -webkit-linear-gradient(#59ce8f, #e8f9fd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.join_btn {
  background: #8a2387;
  background: -webkit-linear-gradient(to right, #f27121, #e94057, #8a2387);
  background: linear-gradient(to right, #f27121, #e94057, #8a2387);
  color: #fff;
  text-decoration: none;
  padding: 15px 40px;
  border-radius: 10px;
  font-size: 1.2rem;
  transition: 0.5s;
  font-weight: 500;
}
.join_btn:hover {
  color: #fff !important;
  box-shadow: 0 0 20px #fff;
  transition: 0.3s;
}
/* sm */

.home_slider.sm {
  margin-top: 10px;
  height: 35em;
  padding: 0 5px;
}

.home_slider.sm .slider_content {
  width: calc(100% - 10px);
  padding: 0;
}

.home_slider.sm .carousel-control-prev,
.home_slider.sm .carousel-control-next {
  display: none;
}

.home_slider.sm .slider_content h1 {
  font-size: 3rem;
}

.home_slider.sm .slider_content h4 {
  font-size: 1rem;
}

.home_slider.sm .slider_content .join_btn {
  padding: 13px 30px;
  font-size: 1rem;
}

.home_slider.md {
  height: 35em;
}
