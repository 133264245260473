.adminviewmill thead .heading{
  background-color: #EAF6F6;
   text-transform: capitalize;
   font-weight: 800;
   color: #000 !important;
 }

 .adminviewmill thead th{
   color: rebeccapurple;
   font-weight: 800;
 }
 .adminviewmill .col-sm-12.col-lg-6.move {
  margin-top: 25px ;
}
.adminviewmill .col-sm-12.col-lg-6:nth-of-type(1),
.adminviewmill .col-sm-12.col-lg-6:nth-of-type(2) {
  margin: 0px;
}

@media (max-width: 426px) {
  #main > div > div.adminviewmill > div.row > div:nth-child(1) {
    margin-top: 0px;
  }
  #main > div > div.adminviewmill > div.row > div {
    margin-top: 20px;
  }
}
@media (min-width: 320px) and (max-width: 426px) {
  .view-head {
    padding: 0px;
  }
}

@media (min-width: 427px) and (max-width: 768px) {
  #main > div > div.adminviewmill > div.row > div:nth-child(1)
 {
    margin-top: 0px;
  }
  #main > div > div.adminviewmill > div.row > div {
    margin-top: 20px;
  }
}
