.viewguest .imgbx img {
  position: relative;
}
.viewguest .imgbx a {
  position: absolute;
  top: 40;
  left: 0;
  color: transparent;
  font-size: 1.5rem;
}
