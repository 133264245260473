.presidentmsg {
  padding: 3rem;
}
.presidentmsg h4 {
  text-align: center;
  font-weight: 700;
  font-size: 3.5rem;
  color: #59ce8f;
}
.presidentmsg h4 span {
  color: #000;
}
.presidentmsg .image img {
  height: 550px;
  width: 100%;
}
.presidentmsg .content p {
  font-size: 0.9rem;
  color: #777;
  line-height: 2;
  font-weight: 400;
  padding: 10px;
  margin-top: 40px;
  text-align: justify;
}
.presidentmsg .content p span {
  color: red;
  font-weight: 700;
}

/*  sm*/
.presidentmsg.sm {
  padding: 5px;
}
.presidentmsg.sm h4 {
  text-align: left;
  font-size: 1.6rem;
  margin-top: 20px;
}
.presidentmsg.sm .content p {
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: 10px;
  padding: 0;
}
