.user_auth {
  position: relative;
  min-height: 100vh;
  background-image: url("assets/img/appbg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.user_auth.forget_password {
  background-image: url("assets/img/appbg.jpg") !important;
}
.user_auth .formBx form svg {
  font-size: 3rem;
  margin-left: 40%;
}
.user_auth .container {
  position: relative;
  width: 800px;
  height: 650px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 10px;
}

.user_auth .container .user {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.user_auth .container .user .imgBx {
  position: relative;
  width: 50%;
  height: 100%;
  background: #fff;
  transition: 0.5s;
}

.user_auth .container .imgBx .carousel {
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: #333; */
  z-index: 111;
}
.user_auth .container .imgBx .carousel .carousel-item,
.user_auth .container .imgBx .carousel .carousel-inner {
  position: static;
}

.user_auth .container .user .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user_auth .container .user .formBx {
  position: relative;
  width: 50%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  transition: 0.5s;
}

.user_auth .container .user .formBx form h2 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  color: #555;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.user_auth .container .user .formBx form input,
.user_auth .container .user .formBx form select {
  position: relative;
  width: 100%;
  padding: 10px;
  background: #eee;
  color: #000;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 4.5px 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 300;
  border-radius: 6px;
}
.user_auth .container .user .formBx form input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.user_auth .container .user .formBx form input[type="number"] {
  -moz-appearance: textfield;
}

.user_auth .container .user .formBx form button[type="submit"] {
  width: 100%;
  background: var(--primary);
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.5s;
  border: 0;
  border-radius: 5px;
  padding: 10px 0;
  margin-top: 5px;
}

.user_auth .container .user .formBx form .signup {
  position: relative;
  margin-top: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  color: #555;
  text-transform: uppercase;
  font-weight: 300;
}

.user_auth .container .user .formBx form .signup a {
  font-weight: 600;
  text-decoration: none;
  color: var(--primary);
}

.user_auth .container .signupBx {
  pointer-events: none;
}

.user_auth .container.active .signupBx {
  pointer-events: initial;
}

.user_auth .container .signupBx .formBx {
  left: 100%;
}

.user_auth .container.active .signupBx .formBx {
  left: 0;
}

.user_auth .container .signupBx .imgBx {
  left: -100%;
}

.user_auth .container.active .signupBx .imgBx {
  left: 0%;
}

.user_auth .container .signinBx .formBx {
  left: 0%;
}

.user_auth .container.active .signinBx .formBx {
  left: 100%;
}

.user_auth .container .signinBx .imgBx {
  left: 0%;
}

.user_auth .container.active .signinBx .imgBx {
  left: -100%;
}
.user .formBx .password .show_hide {
  color: #777;
  cursor: pointer;
  font-size: 0.5rem !important;
  position: absolute;
  right: 15px;
  top: 10px;
}
.user .formBx .password svg {
  font-size: 1.5rem !important;
  margin-top: 5px;
}
.user_auth .formBx form svg {
  margin-left: 0;
}

@media (max-width: 991px) {
  .user_auth .container {
    max-width: 400px;
  }

  .user_auth .container .imgBx {
    display: none;
  }

  .user_auth .container .user .formBx {
    width: 100%;
  }
}

@media (min-width: 300px) and (max-width: 767px) {
  .user_auth .container .user .formBx {
    padding: 0 25px;
  }
}

/* Otp Modal */

.otp_alert input {
  padding: 10px 15px;
  border: 0;
  background-color: #eee;
  border-radius: 5px;
}
.otp_alert input:focus {
  border: 0;
  outline: none;
}
.otp_alert button {
  background-color: var(--primary);
  border: 0;
  outline: none;
  padding: 10px 0;
  font-weight: 600;
  border-radius: 5px;
}
