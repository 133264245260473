.abtpage {
  padding: 3rem;
}
.abtpage .content {
  position: relative;
}
.abtpage .image img {
  width: 100%;
  height: 400px;
  margin-top: 20%;
}
.abtpage .background-image img {
  opacity: 0.1;
  margin-left: 15%;
  margin-top: 10%;
  position: absolute;
  width: 400px;
  height: 400px;
}

.abtpage .cotton-image img {
  width: 150px;
  height: 150px;
}
.abtpage .cotton-box p {
  font-weight: 500;
  text-align: justify;
  margin-top: 15px;
  color: #777;
}
/* para */

.abtpage .content-para h1 {
  font-weight: 700;
  color: red;
  font-size: 3rem;
  margin-top: 30px;
  margin-bottom: 30px;
}
.abtpage .content-para h1 span {
  color: #000;
}
.abtpage .content-para p {
  font-weight: 700;
  text-align: justify;
font-family: inherit;
  color: #333;
}
.abtpage .content-para .txt svg {
  color: red;
  margin-right: 5px;
  font-size: 1rem;
}
.abtpage .content-para .image img {
  height: 650px;
  width: 100%;
  opacity: 1px;
}

@media (min-width: 320px) and (max-width: 426px) {
  .abtpage {
    padding: 1rem;
  }
  .abtpage .image img {
    width: 100%;
    height: 100%;
    margin-left: 0%;
    display: none;
  }

  .abtpage .cotton-image img {
    width: 100px;
    height: 100px;
    margin-left: 35%;
  }

  .abtpage .background-image img {
    width: 100%;
    height: 100%;
    margin-top: 100%;
    margin-left: 0;
  }
  .abtpage .content-para h1 {
    font-size: 1.4rem;
  }
}

@media (min-width: 427px) and (max-width: 768px) {
  .abtpage .background-image img {
    margin-left: 20%;
    margin-top: 15%;
  }
}
